import moment from "moment"
import {FormID} from "../interfaces"

const APPLICATION_TO_ENROLL = "application_ready_to_enroll"

const formatDateMonthDayYear = (date) => moment(date).format(" MMMM D [,] YYYY")

const formatStandarUSDate = (date) => moment(date).format(" MM/DD/YYYY")

const REFILL_FORMS: FormID[] = ["enrollment_agreement", "opt_outs"]

const ENROLLMENT_APPLICATION = "enrollment_application"

const FORM_ENROLLMENT_APPLICATION_SIZE = 2

const READY_TO_ENROLL_FORM = "application_ready_to_enroll"

const STAFF_ROLE = "staff"

const [FILL, FILLED] = ["#add0ff", "#a4e98f"]

const INDEX_ENROLLMENT_DOCS = 2

const INITIAL_INDEX = 0

const ENROLLMENT_AGREEMENT_WILDCARD = "enrollment_agreement_"

const YesNo = {
    yes: "yes",
    no: "no"
}

const SIGNATURE_DEFINED_USERS = {
    keyStudent: {
        prescedence: 0,
        role: "student"
    },
    keyParent: {
        prescedence: 1,
        role: "Parent"
    },
    keyAdvisor: {
        prescedence: 2,
        role: "Advisor"
    },
    keySchoolDirector: {
        prescedence: 3,
        role: "School Director"
    }
}

const ADMISSIONS_DOCS_SIGNATURES = {
    Parent: [
        {
            // signatureKey: "parent_signature_advertising"
        }
    ],
    Advisor: [
        {
            signatureKey: "admission_advisor_diploma"
        },
        {
            signatureKey: "admissions_advisor_verification"
        }
    ],
    "School Director": [
        {
            signatureKey: "school_director_advertising"
        },
        {
            signatureKey: "school_director_da_atts"
        }
    ]
}

const ENROLLMENT_AGREEMENT_SIGNATURES = {
    Parent: {
        enrollment_agreement_rtb: [
            {
                signatureKey: "parent_signature_ea_rtb"
            }
        ],
        enrollment_agreement_hispanic_rtb: [
            {
                signatureKey: "parent_signature_ea_rtb_h_m"
            }
        ],
        enrollment_agreement_rtb_lc: [
            {
                signatureKey: "parent_signature_ea_rtb_lc"
            }
        ],
        enrollment_agreement_rtb_miami: [
            {
                signatureKey: "parent_signature_ea_rtb_m"
            }
        ],
        enrollment_agreement_emp_apc: [
            {
                signatureKey: "parent_signature_ea_emp"
            }
        ],
        enrollment_agreement_emp_apc_lc: [
            {
                signatureKey: "parent_signature_ea_emp_lc"
            }
        ],
        enrollment_agreement_emp_apc_miami: [
            {
                signatureKey: "parent_signature_ea_emp_m"
            }
        ],
        enrollment_agreement_aas: [
            {
                signatureKey: "parent_signature_ea_aas_m"
            }
        ]
    },
    Advisor: {
        enrollment_agreement_rtb: [
            {
                signatureKey: "admission_advisor_ea_rtb"
            }
            // {
            //     signatureKey: "admission_advisor_authorization_ea_rtb"
            // }
        ],
        enrollment_agreement_hispanic_rtb: [
            {
                signatureKey: "admission_advisor_ea_rtb_h_m"
            }
            // {
            //     signatureKey: "admission_advisor_authorization_ea_rtb_h_m"
            // }
        ],
        enrollment_agreement_rtb_lc: [
            {
                signatureKey: "admission_advisor_ea_rtb_lc"
            }
            // {
            //     signatureKey: "admission_advisor_authorization_ea_rtb_lc"
            // }
        ],
        enrollment_agreement_rtb_miami: [
            {
                signatureKey: "admission_advisor_ea_rtb_m"
            }
            // {
            //     signatureKey: "admission_advisor_authorization_ea_rtb_m"
            // }
        ],
        enrollment_agreement_emp_apc: [
            {
                signatureKey: "admission_advisor_ea_emp"
            }
            // {
            //     signatureKey: "admission_advisor_authorization_ea_emp"
            // }
        ],
        enrollment_agreement_emp_apc_lc: [
            {
                signatureKey: "admission_advisor_ea_emp_lc"
            }
            // {
            //     signatureKey: "admission_advisor_authorization_ea_emp_lc"
            // }
        ],
        enrollment_agreement_emp_apc_miami: [
            {
                signatureKey: "admission_advisor_ea_emp_m"
            }
            // {
            //     signatureKey: "admission_advisor_authorization_ea_emp_m"
            // }
        ],
        enrollment_agreement_aas: [
            {
                signatureKey: "admission_advisor_ea_aas_m"
            }
            // {
            //     signatureKey: "admission_advisor_authorization_ea_aas_m"
            // }
        ]
    },
    "School Director": {
        enrollment_agreement_rtb: [
            {
                signatureKey: "school_director_ea_rtb"
            }
            // {
            //     signatureKey: "school_director_ea_atts_rtb"
            // }
        ],
        enrollment_agreement_hispanic_rtb: [
            {
                signatureKey: "school_director_ea_rtb_h_m"
            },
            {
                signatureKey: "school_director_ea_mms_rtb_h_m"
            }
            // {
            //     signatureKey: "school_director_ea_atts_rtb_h_m"
            // }
        ],
        enrollment_agreement_rtb_lc: [
            {
                signatureKey: "school_director_ea_rtb_lc"
            }
            // {
            //     signatureKey: "school_director_ea_atts_rtb_lc"
            // }
        ],
        enrollment_agreement_rtb_miami: [
            {
                signatureKey: "school_director_ea_rtb_m"
            },
            {
                signatureKey: "school_director_ea_mms_rtb_m"
            }
            // {
            //     signatureKey: "school_director_ea_atts_rtb_m"
            // }
        ],
        enrollment_agreement_emp_apc: [
            {
                signatureKey: "school_director_ea_emp"
            }
            // {
            //     signatureKey: "school_director_ea_atts_emp"
            // }
        ],
        enrollment_agreement_emp_apc_lc: [
            {
                signatureKey: "school_director_ea_emp_lc"
            }
            // {
            //     signatureKey: "school_director_ea_atts_emp_lc"
            // }
        ],
        enrollment_agreement_emp_apc_miami: [
            {
                signatureKey: "school_director_ea_emp_m"
            },
            {
                signatureKey: "school_director_ea_mms_emp_m"
            }
            // {
            //     signatureKey: "school_director_ea_atts_emp_m"
            // }
        ],
        enrollment_agreement_aas: [
            {
                signatureKey: "school_director_ea_aas_m"
            },
            {
                signatureKey: "school_director_ea_mms_aas_m"
            }
            // {
            //     signatureKey: "school_director_ea_atts_aas_m"
            // }
        ]
    }
}

const TABS_FORMS = [
    {
        id: 0,
        type: "tab",
        title: "Application",
        enabled: true,
        subTabs: [
            {
                id: 0,
                type: "pdf",
                title: "Application For Admissions",
                enabled: true,
                form_id: "document_for_admission"
            }
        ]
    },
    {
        id: 1,
        type: "tab",
        title: "Admissions Documents",
        enabled: true,
        bgColor: FILL,
        isChildsFilled: false,
        subTabs: [
            {
                id: 0,
                type: "pdf",
                title: "Admissions Docs",
                enabled: true,
                bgColor: FILL,
                form_id: "document_admission_pkg",
                childSignaturesKey: []
            }
        ]
    },
    {
        id: 2,
        type: "tab",
        title: "Enrollment Agreement",
        enabled: true,
        bgColor: FILL,
        isChildsFilled: false,
        subTabs: []
    },
    {
        id: 3,
        type: "tab",
        title: "Survey Quiz Form",
        enabled: true,
        subTabs: [
            {
                id: 0,
                type: "pdf",
                title: "Survey Quiz",
                enabled: true,
                form_id: "survey_quiz"
            }
        ]
    }
]

const UPLOAD_DOCS_TAB = {
    id: 4,
    type: "tab",
    title: "Upload Docs",
    enabled: true,
    subTabs: []
}

const COVID_DOCS_TAB = {
    id: 4,
    type: "tab",
    title: "Vaccination Policy",
    enabled: true,
    subTabs: [
        {
            id: 0,
            type: "pdf",
            title: "Vaccination Policy",
            enabled: true,
            form_id: "vaccination_policy"
        }
    ]
}

const CAMPUSES_WITH_VACCINATION_POLICY = [{code: "Lombard"}, {code: "Chicago"}] // todo: get campuses from db

const removeJsonDataForms = (currentJsonDataForms) => {
    return Object.keys(currentJsonDataForms)
        .filter((key) => !REFILL_FORMS.includes(key as FormID))
        .reduce((obj, key) => {
            obj[key] = currentJsonDataForms[key]
            return obj
        }, {})
}

const currentSizeForm = (currentJsonDataForms, constantForms) => {
    const jsonFormsKeys = Object.keys(currentJsonDataForms)
    let counter = 0
    for (let i = 0; i < jsonFormsKeys.length; i++) {
        const elementKey = jsonFormsKeys[i]
        if (constantForms.includes(elementKey as FormID)) counter += 1
    }
    return counter
}

const isInJsonData = (jsonData, signatureKeys) => {
    if (signatureKeys && signatureKeys.length > 0) {
        let counter = 0
        const size = signatureKeys.length
        for (let i = 0; i < signatureKeys.length; i++) {
            const signature = signatureKeys[i]
            if (jsonData[signature.signatureKey]) {
                counter += 1
            }
        }
        // console.log("@@@@@@@@@@@@@@@@@", counter, size)
        return counter === size
    }
    return false
}

const iterateInJsonData = (jsonData, subTabs) => {
    const signaturesArray = Array(subTabs.length).fill(false)
    for (let i = 0; i < signaturesArray.length; i++) {
        signaturesArray[i] = isInJsonData(jsonData, subTabs[i].childSignaturesKey)
    }
    return signaturesArray.length > 0
        ? signaturesArray.filter((value) => value === true).length === subTabs.length
        : false
}

const [DISCLOSURES, ADDITIONAL_DOCS] = ["Disclosures", "Additional Docs"]

const ADMISSIONS_DOCUMENTS_TITLE = "Admissions Documents"
const ENROLLMENT_AGREEMENT_TITLE = "Enrollment Agreement"

const [
    STAFF_SIGNATURE_KEY,
    STAFF_BIO_SIGNATURE_KEY,
    STAFF_ELECTRONIC_SIGNATURE_KEY,
    STAFF_ACOMODATION_SIGNATURE_KEY
] = ["staff", "staff_bio", "staff_electronic", "staff_acomodation"]

const changeSignedColor = (bgColor, title, jsonData, subTabs) => {
    if (bgColor && subTabs) {
        if (title === ADMISSIONS_DOCUMENTS_TITLE && iterateInJsonData(jsonData, subTabs)) {
            return FILLED
        }
        if (title === ENROLLMENT_AGREEMENT_TITLE && iterateInJsonData(jsonData, subTabs)) {
            return FILLED
        }
        return FILL
    }
    return "#FFFFFF"
}

const convertToSubTabsFormatSubmittedDocs = (
    submittedForms,
    enrollments_agreements_to_sign,
    role
) => {
    const submittedFormsKeys = Object.keys(submittedForms).filter((keyName) =>
        keyName.includes(ENROLLMENT_AGREEMENT_WILDCARD)
    )
    const subTabs = []
    for (let i = 0; i < submittedFormsKeys.length; i++) {
        const formKeyName = submittedFormsKeys[i]
        // console.log("@@@@@@@@@@@@@@@@@@@@", formKeyName)
        enrollments_agreements_to_sign.push(formKeyName)
        const tab = {
            ...submittedForms[formKeyName],
            form_id: formKeyName,
            id: i,
            bgColor: FILL,
            // here add dynamic validations for EA ... getSiganturesValidationsByRoleStatusDocAndEnrollment("doc.json_data.role", "doc.status", formKeyName)
            childSignaturesKey: getSiganturesValidationsByRoleStatusDocAndEnrollment(
                role,
                formKeyName
            )
        }
        subTabs.push(tab)
    }
    return subTabs.sort((a, b) => a.order - b.order)
}

const getSiganturesValidationsByRoleStatusDocAndEnrollment = (role, formTabKey) => {
    let checkMarksEnrollmentAgreementSignatures
    switch (role) {
        case SIGNATURE_DEFINED_USERS.keyParent.role:
            checkMarksEnrollmentAgreementSignatures =
                ENROLLMENT_AGREEMENT_SIGNATURES[role][formTabKey]
            break

        case SIGNATURE_DEFINED_USERS.keyAdvisor.role:
            checkMarksEnrollmentAgreementSignatures =
                ENROLLMENT_AGREEMENT_SIGNATURES[role][formTabKey]
            break

        case SIGNATURE_DEFINED_USERS.keySchoolDirector.role:
            checkMarksEnrollmentAgreementSignatures =
                ENROLLMENT_AGREEMENT_SIGNATURES[role][formTabKey]
            break

        default:
            checkMarksEnrollmentAgreementSignatures = [
                "No defined users found for enrollment agreements."
            ]
            break
    }
    return checkMarksEnrollmentAgreementSignatures
}

const getAdmissionsDocumentsSignaturesByRoleAndStatusDoc = (doc) => {
    const {
        json_data: {role}
    } = doc
    let checkMarksAdmissionsDocSignatures
    switch (role) {
        case SIGNATURE_DEFINED_USERS.keyParent.role:
            checkMarksAdmissionsDocSignatures = ADMISSIONS_DOCS_SIGNATURES[role]
            break

        case SIGNATURE_DEFINED_USERS.keyAdvisor.role:
            checkMarksAdmissionsDocSignatures = ADMISSIONS_DOCS_SIGNATURES[role]
            break

        case SIGNATURE_DEFINED_USERS.keySchoolDirector.role:
            checkMarksAdmissionsDocSignatures = ADMISSIONS_DOCS_SIGNATURES[role]
            break

        default:
            checkMarksAdmissionsDocSignatures = ["No defined users found for admissions documents."]
            break
    }
    return checkMarksAdmissionsDocSignatures
}

const DEFAULT_ROLES = {
    parent: "Parent",
    schoolDirector: "School Director",
    admissionsAdvisor: "Advisor",
    financialAidDirector: "Financial Aid Director"
}

export {
    formatDateMonthDayYear,
    formatStandarUSDate,
    removeJsonDataForms,
    currentSizeForm,
    ENROLLMENT_APPLICATION,
    FORM_ENROLLMENT_APPLICATION_SIZE,
    READY_TO_ENROLL_FORM,
    APPLICATION_TO_ENROLL,
    TABS_FORMS,
    UPLOAD_DOCS_TAB,
    STAFF_ROLE,
    isInJsonData,
    FILLED,
    changeSignedColor,
    STAFF_SIGNATURE_KEY,
    STAFF_BIO_SIGNATURE_KEY,
    STAFF_ELECTRONIC_SIGNATURE_KEY,
    STAFF_ACOMODATION_SIGNATURE_KEY,
    DISCLOSURES,
    ADDITIONAL_DOCS,
    INDEX_ENROLLMENT_DOCS,
    convertToSubTabsFormatSubmittedDocs,
    getAdmissionsDocumentsSignaturesByRoleAndStatusDoc,
    INITIAL_INDEX,
    ADMISSIONS_DOCUMENTS_TITLE,
    iterateInJsonData,
    ENROLLMENT_AGREEMENT_TITLE,
    DEFAULT_ROLES,
    COVID_DOCS_TAB,
    YesNo,
    SIGNATURE_DEFINED_USERS,
    CAMPUSES_WITH_VACCINATION_POLICY
}
