export function getParameterByName(name, url?) {
    if (!url) url = window.location.href
    name = name.replace(/[[\]]/g, "\\$&")
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ""
    return decodeURIComponent(results[2].replace(/\+/g, " "))
}

export function getIdsFromPathParams(path) {
    const results = path.trim().split("/")
    if (results.length <= 0) return ""
    if (results.length > 3) return ""
    const [, stringUserId, stringTaskUserId] = results
    const taskUserId = Number(stringTaskUserId.trim())
    const userId = Number(stringUserId.trim())
    const isTaskUserIdInteger = Number.isInteger(taskUserId)
    const isUserIdInteger = Number.isInteger(userId)
    if (!isTaskUserIdInteger && !isUserIdInteger) {
        return ""
    }
    return {
        taskUserId,
        userId
    }
}
