import React, {Component} from "react"
import moment from "moment"
import {Model} from "Model"
import {observer} from "mobx-react"
import {computed} from "mobx"
import styles from "./DetailsHeader.module.css"
import {DEFAULT_ROLES} from "helper/Methods"
// import "./DetailsHeader.module.css"

interface Props {
    model: Model
    className?: string
    setTabForms?: Function
    cancelUrl?: any
    onReject?: any
    taskToken: any
    showApproverMessage?: boolean
    classNameWrap?: string
    submit?: any
}

@observer
export class DetailsHeader extends Component<Props, {}> {
    @computed
    private get nextButtonEnabled(): boolean {
        const model = this.props.model
        if (model.getNextFormId() === null) {
            return false
        }
        return true
    }

    render() {
        const {
            taskToken,
            cancelUrl,
            onReject,
            showApproverMessage = false,
            classNameWrap = ""
        } = this.props
        const {model} = this.props

        const handleRedirect = () => {
            window.top.location.href = cancelUrl
        }

        return (
            <React.Fragment>
                {taskToken && (
                    <div
                        className={
                            classNameWrap === "" ? styles.bodyWrap + " bodyWrap" : classNameWrap
                        }>
                        <p className={styles.title}>{taskToken?.task?.name}</p>
                        <p className={styles.description}>
                            Complete the fields to submit this task
                        </p>
                        <div className={styles.bodyInfoWrap}>
                            <div
                                className={`${styles.bodyInfoItem} ${styles.oneLine}`}
                                style={{minWidth: 110}}>
                                <p className={styles.bodyInfoItem__title}>Requested:</p>
                                <p className={styles.bodyInfoItem__value}>
                                    {moment(taskToken?.task?.createdAt)
                                        .local()
                                        .format("MM/DD/YYYY")}
                                </p>
                            </div>
                            <div
                                className={`${styles.bodyInfoItem} ${styles.oneLine}`}
                                style={{minWidth: 110}}>
                                <p className={styles.bodyInfoItem__title}>Due date:</p>
                                <p className={styles.bodyInfoItem__value}>{``}</p>
                            </div>
                            <div
                                className={`${styles.bodyInfoItem} ${styles.oneLine}`}
                                style={{minWidth: 50}}>
                                <p className={styles.bodyInfoItem__title}>Status:</p>
                                <p className={styles.bodyInfoItem__value}>
                                    {taskToken?.task?.status}
                                </p>
                            </div>
                            <div
                                className={`${styles.bodyInfoItem} ${styles.oneLine}`}
                                style={{minWidth: 140}}>
                                <p className={styles.bodyInfoItem__title}>Type:</p>
                                <p className={styles.bodyInfoItem__value}>
                                    {taskToken?.task?.type}
                                </p>
                            </div>
                            <div className={`${styles.bodyInfoItem} ${styles.oneLine}`}>
                                <p className={styles.bodyInfoItem__title}>Description:</p>
                                <p className={styles.bodyInfoItem__value}>
                                    {taskToken?.task?.description}
                                </p>
                            </div>
                        </div>
                        <div className={styles.actionWrap}>
                            <div className={styles.leftActionWrap}>
                                {cancelUrl && (
                                    <button
                                        type="button"
                                        className={styles.leftActionBtn}
                                        onClick={() => handleRedirect()}>
                                        <span>Cancel</span>
                                    </button>
                                )}
                                {model.document.json_data.role === DEFAULT_ROLES.parent ? (
                                    <button
                                        className={`${styles.baseBtn} ${styles.signature}`}
                                        onClick={() => {
                                            model.signaturePopup = {
                                                show: true,
                                                signature_key: "",
                                                mode: "Parent"
                                            }
                                        }}>
                                        My Signature
                                    </button>
                                ) : model.document.json_data.role ===
                                  DEFAULT_ROLES.admissionsAdvisor ? (
                                    <button
                                        className={`${styles.baseBtn} ${styles.signature}`}
                                        onClick={() => {
                                            model.signaturePopup = {
                                                show: true,
                                                signature_key: "",
                                                mode: "Advisor"
                                            }
                                        }}>
                                        My Signature
                                    </button>
                                ) : (
                                    <button
                                        className={`${styles.baseBtn} ${styles.signature}`}
                                        onClick={() => {
                                            model.signaturePopup = {
                                                show: true,
                                                signature_key: "",
                                                mode: "School Director"
                                            }
                                        }}>
                                        My Signature
                                    </button>
                                )}
                            </div>
                            <div className={styles.rightActionWrap}>
                                <button
                                    type="button"
                                    className={`${styles.baseBtn} ${styles.rejectBtn}`}
                                    onClick={onReject}>
                                    <span>Reject</span>
                                </button>
                                <button
                                    type="button"
                                    className={`${styles.baseBtn} ${styles.saveBtn}`}
                                    disabled={model.isIframeLoading}
                                    onClick={async () => {
                                        const success = await model.onSaveFoms()
                                        if (!success) {
                                            alert("Please check errors")
                                        }
                                        this.props.setTabForms()
                                    }}>
                                    <span>Save</span>
                                </button>

                                <button
                                    type="button"
                                    className={`${styles.baseBtn} ${styles.submitBtn}`}
                                    id="submit_form_button"
                                    disabled={this.props.model.isEnableSubmitButton()}
                                    onClick={async () => {
                                        await model.submit()
                                    }}>
                                    <span>Submit</span>
                                </button>
                                {/* {!submit &&
                                } */}
                            </div>
                        </div>
                        {showApproverMessage && (
                            <div className={styles.bottomText2}>
                                Document Approver: Click SUBMIT if you approve and the document will
                                be routed to the next signer. Click REJECT to reject the document
                                and Task creator will be notified.
                            </div>
                        )}
                    </div>
                )}
            </React.Fragment>
        )
    }
}
