import {Model} from "Model"
import React, {Component} from "react"
import {ReactComponent as CheckTabIcon} from "./check-tab.svg"
import {ReactComponent as UncheckTabIcon} from "./minus-circle.svg"
import styles from "./CheckMark.module.css"
import {isInJsonData} from "helper/Methods"

interface Props {
    form_id: string
    bgColor: string
    title: string
    signatureKeys?: Array<any>
    model: Model
}

export class CheckMark extends Component<Props, {}> {
    public static defaultProps = {
        type: "text",
        errors: {}
    }

    private isFilledSignatureChecked = (
        bgColor: string,
        jsonData: any,
        formId: string,
        signatureKeys: Array<any>
    ) => {
        const {forms} = jsonData
        if (bgColor) {
            const sizeJsonDataKeys = isInJsonData(jsonData, signatureKeys)
            if (Object.keys(forms).includes(formId) && sizeJsonDataKeys) {
                return <CheckTabIcon className={`${styles.icon}`} />
            } else {
                return <UncheckTabIcon className={`${styles.icon}`} />
            }
        }
        return null
    }

    public render() {
        const {form_id, bgColor, title, model, signatureKeys} = this.props
        const {isFilledSignatureChecked} = this
        return (
            <div className={styles.containerCheckmark}>
                <div className={styles.check}>
                    {isFilledSignatureChecked(
                        bgColor,
                        model?.document?.json_data,
                        form_id,
                        signatureKeys
                    )}
                    <span>{title}</span>
                </div>
            </div>
        )
    }
}
