import {Model} from "./Model"

export class Bridge {
    constructor(public model: Model) {}

    public async getStaffSignature(
        signature_key: "student_initials_technicalStandards_1" | string
    ) {
        const staffSignature = await this.model.getStaffSignature("Advisor")
        if (!staffSignature) {
            this.model.signaturePopup = {
                show: true,
                signature_key: signature_key,
                mode: "Advisor"
            }
        }
        return staffSignature
    }

    public async getSchoolDirectorSignature(
        signature_key: "student_initials_technicalStandards_1" | string
    ) {
        const staffSignature = await this.model.getStaffSignature("School Director")
        if (!staffSignature) {
            this.model.signaturePopup = {
                show: true,
                signature_key: signature_key,
                mode: "School Director"
            }
        }
        return staffSignature
    }

    public async getParentSignature(
        signature_key: "student_initials_technicalStandards_1" | string
    ) {
        const parentSignature = await this.model.getStaffSignature("Parent")
        if (!parentSignature) {
            this.model.signaturePopup = {
                show: true,
                signature_key: signature_key,
                mode: "Parent"
            }
        }
        return parentSignature
    }
}
