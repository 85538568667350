import React, {Component} from "react"
import styles from "./CareerInformation.module.css"
import {JsonDocument, Model, CareerDocument} from "../../Model"
import {observable} from "mobx"
import {findErrors} from "../../lib/functions/findErrors"
import {observer} from "mobx-react"
import {Tab, Tabs, TabList, TabPanel} from "react-tabs"
import {Main} from "../../pages/main"
import {TabDocs} from "pages/tab-docs"
import {CheckMark} from "components/CheckMark"
import {
    FILLED,
    isInJsonData,
    changeSignedColor,
    iterateInJsonData,
    ADMISSIONS_DOCUMENTS_TITLE,
    ENROLLMENT_AGREEMENT_TITLE
} from "helper/Methods"
import {ReactComponent as CheckTabIcon} from "components/CheckMark/check-tab.svg"
import {ReactComponent as UncheckTabIcon} from "components/CheckMark/minus-circle.svg"
import "react-tabs/style/react-tabs.css"
interface Props {
    model: Model
}

// const mandatory = {
//     presence: {allowEmpty: false}
// }
const rules = {}

@observer
export class CareerInformation extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}
    private career_data: CareerDocument
    private CAREER_KEYS = [
        "campus",
        "program",
        "advisor",
        "year",
        "campuses",
        "course_load",
        "times"
    ]
    private EXTRA_INFO = "extra"

    constructor(props) {
        super(props)
        console.log("Inside constructor CareerInformation")

        this.onChange = this.onChange.bind(this)
        this.onInitFillSelectOptions = this.onInitFillSelectOptions.bind(this)
        this.onModel = this.onModel.bind(this)
        this.json_data = props.model.document.json_data
        if (!this.json_data[this.EXTRA_INFO]) {
            this.initExtraInfo() // todo: careful with this.
        }
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    private findByKey(list, keyField) {
        return list.find(({code}) => code === keyField)
    }
    private initExtraInfo() {
        const extra = this.EXTRA_INFO
        this.json_data[extra] = {}
    }

    public onInitFillSelectOptions() {}

    public async componentDidUpdate() {
        this.onInitFillSelectOptions()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
        this.onInitFillSelectOptions()
        this.forceUpdate()
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        console.log("Validate CareerInformation")
        const {isValid, errors} = findErrors(this.json_data, rules)
        this.errors = errors

        return isValid
    }

    private onChange(id: string, value) {
        this.json_data[id] = value
        this.onInitFillSelectOptions()
        this.props.model.dispatcher2.dispatch()
        this.forceUpdate()
    }

    private isFilledSignatureChecked = (
        bgColor: string,
        jsonData: any,
        title: string,
        subTabs: Array<any>,
        indexTab: number
    ) => {
        if (bgColor && subTabs) {
            if (title === ADMISSIONS_DOCUMENTS_TITLE && iterateInJsonData(jsonData, subTabs)) {
                this.props.model.setIndexTab(indexTab)
                return <CheckTabIcon className={`${styles.icon}`} />
            }
            if (title === ENROLLMENT_AGREEMENT_TITLE && iterateInJsonData(jsonData, subTabs)) {
                this.props.model.setIndexTab(indexTab)
                return <CheckTabIcon className={`${styles.icon}`} />
            }
            return <UncheckTabIcon className={`${styles.icon}`} />
        }
        return null
    }

    public render() {
        const model = this.props.model
        // const p = {model, onChange: this.onChange, errors: this.errors}
        // const r = {onChange: this.onChange, json_data: this.json_data}
        const {isFilledSignatureChecked} = this
        return (
            <React.Fragment>
                <div className={styles.root}>
                    {/* <div className={styles.staffInformation}>
                        <span className={styles.name}>
                            {" "}
                            <strong>Welcome</strong> {this.json_data?.extra?.advisor?.name}{" "}
                        </span>
                        <span className={styles.email}>
                            ({this.json_data?.extra?.advisor?.email})
                        </span>
                    </div> */}
                    {/* <h2 className={styles.h2}>
                        There is some documents that requires your signature
                    </h2> */}
                    <div className={styles.fillSections}>
                        <span className={styles.mark}></span>
                        <span>
                            <strong>*</strong>
                            <i>The coloured sections indicates where you need to sign</i>
                        </span>
                    </div>
                    <div className={styles.form}>
                        {this.props.model.getTabForms().length > 0 && (
                            <Tabs
                                forceRenderTabPanel={false}
                                defaultIndex={0}
                                onSelect={(index) => {
                                    if (this.props.model.getTabForms()[index].subTabs[0]) {
                                        this.props.model.formTabSelected = this.props.model.getTabForms()[
                                            index
                                        ].subTabs[0]
                                        this.props.model.setFormTabId(
                                            this.props.model.formTabSelected.form_id
                                        )
                                    }
                                }}>
                                <TabList>
                                    {this.props.model
                                        .getTabForms()
                                        .map(({title, bgColor, subTabs}, i) => (
                                            <Tab
                                                style={{
                                                    backgroundColor: changeSignedColor(
                                                        bgColor,
                                                        title,
                                                        this.json_data,
                                                        subTabs
                                                    )
                                                }}
                                                key={i}>
                                                <div className={styles.check}>
                                                    {isFilledSignatureChecked(
                                                        bgColor,
                                                        this.json_data,
                                                        title,
                                                        subTabs,
                                                        i
                                                    )}
                                                    <span>{title}</span>
                                                </div>
                                            </Tab>
                                        ))}
                                </TabList>
                                {this.props.model.getTabForms().map(({subTabs, id}, i) => {
                                    // console.log("subTabs", toJS(subTabs))
                                    if (subTabs.length > 0) {
                                        return (
                                            <TabPanel key={i}>
                                                <Tabs
                                                    onSelect={(index) => {
                                                        if (
                                                            this.props.model.getTabForms()[id]
                                                                .subTabs[index]
                                                        ) {
                                                            this.props.model.formTabSelected = this.props.model.getTabForms()[
                                                                id
                                                            ].subTabs[index]
                                                            this.props.model.setFormTabId(
                                                                this.props.model.formTabSelected
                                                                    .form_id
                                                            )
                                                        }
                                                    }}
                                                    defaultIndex={0}
                                                    forceRenderTabPanel={false}>
                                                    <TabList>
                                                        {subTabs.map(
                                                            (
                                                                {
                                                                    title,
                                                                    bgColor,
                                                                    form_id,
                                                                    childSignaturesKey
                                                                },
                                                                j
                                                            ) => {
                                                                return (
                                                                    <Tab
                                                                        style={{
                                                                            backgroundColor: isInJsonData(
                                                                                model.document
                                                                                    .json_data,
                                                                                childSignaturesKey
                                                                            )
                                                                                ? FILLED
                                                                                : bgColor
                                                                        }}
                                                                        key={j}>
                                                                        <CheckMark
                                                                            bgColor={bgColor}
                                                                            title={title}
                                                                            form_id={form_id}
                                                                            model={model}
                                                                            signatureKeys={
                                                                                childSignaturesKey
                                                                            }
                                                                        />
                                                                    </Tab>
                                                                )
                                                            }
                                                        )}
                                                    </TabList>
                                                    {subTabs.map(({form_id}, k) => (
                                                        <TabPanel key={k}>
                                                            <Main
                                                                model={model}
                                                                form_id={form_id}></Main>
                                                        </TabPanel>
                                                    ))}
                                                </Tabs>
                                            </TabPanel>
                                        )
                                    }
                                    return (
                                        <TabPanel key={i}>
                                            <TabDocs model={model} />
                                        </TabPanel>
                                    )
                                })}
                            </Tabs>
                        )}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
