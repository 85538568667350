import React, {Component} from "react"
import styles from "./Footer.module.css"
import {Model} from "Model"

interface Props {
    model: Model
}

export class Footer extends Component<Props, {}> {
    public render() {
        const {campus} = this.props.model.document.json_data.extra

        return (
            <footer className={styles.root}>
                <div className={styles.wrapper}>
                    <address className={styles.address}></address>
                    <p className={styles.text}>
                        <strong>BeOnAir Network</strong>
                    </p>
                    {!campus ? (
                        <div>
                            <p className={styles.text}>
                                Discover a Media School That’s Right For You
                            </p>
                        </div>
                    ) : (
                        <div>
                            <p className={styles.text}>
                                {campus.name} | {campus.address}, {campus.state} | {campus.phone}
                            </p>
                        </div>
                    )}
                </div>
            </footer>
        )
    }
}
