import React, {Component} from "react"
import styles from "./TabDocs.module.css"
import {observer} from "mobx-react"
import {observable} from "mobx"

import {Model} from "../../Model"

interface Props {
    model: Model
}

@observer
export class TabDocs extends Component<Props, {}> {
    @observable
    private index = 0

    private iframe: HTMLIFrameElement

    public render() {
        const model = this.props.model
        if (!model.user) {
            return <h1>Error. No token</h1>
        }
        return (
            <div className={styles.root}>
                {this.props.model.document.json_data.uploads.map(({name}, i) => (
                    <div className={styles.fileImg} key={i}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>{name}</td>
                                    <td>
                                        <a
                                            href={`${process.env.REACT_APP_API_DOMAIN}/uploads/${name}`}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <i className="fas fa-eye fa-lg"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
        )
    }
}
