import React, {Component} from "react"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {Model} from "./Model"
import {Gate} from "./lib/gate/Gate"
import {Footer} from "components/Footer"
import {CareerInformation} from "pages/career-information"
import {Loader} from "components/Loader"
import styles from "./App.module.css"
import {DetailsHeader} from "./components/DetailsHeader"
import {SignaturePopup} from "./components/SignaturePopup"

interface Props {
    model: Model
    gate: Gate
}

@observer
export class App extends Component<Props, {}> {
    @observable
    private isLoading = false

    @observable
    private navigation: "open" | "closed" = "open"

    @observable
    private mode: "mobile" | "tablet" | "desktop"

    constructor(props) {
        super(props)

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.forceUpdate()
    }

    private onChangeNavigationState() {
        if (this.navigation === "open") {
            this.navigation = "closed"
        } else {
            this.navigation = "open"
        }
    }
    public render() {
        const {model, gate} = this.props
        return (
            <React.Fragment>
                <Loader isVisible={gate.getLoading()} />
                {model.getIsAuth() ? (
                    <React.Fragment>
                        {model.signaturePopup.show && (
                            <SignaturePopup
                                mode={model.signaturePopup.mode}
                                signature_key={model.signaturePopup.signature_key}
                                gate={gate}
                                model={model}
                                onClose={() => {
                                    model.signaturePopup = {
                                        show: false,
                                        signature_key: "",
                                        mode: "Advisor"
                                    }
                                }}
                            />
                        )}
                        <div className={styles.root}>
                            <main className={styles.main}>
                                <DetailsHeader
                                    model={model}
                                    className={styles.navigationButton}
                                    setTabForms={() => this.forceUpdate()}
                                    taskToken={model.taskToken}
                                    onReject={() => {}}
                                    cancelUrl={``}
                                    // showApproverMessage
                                />
                                <CareerInformation model={model} />
                            </main>
                            {/* <NavigationButton
                                model={model}
                                className={styles.navigationButton}
                                setTabForms={() => this.forceUpdate()}
                            /> */}
                            <Footer model={model} />
                        </div>
                    </React.Fragment>
                ) : (
                    <div>Unauthorized</div>
                )}
            </React.Fragment>
        )
    }

    public async componentDidMount() {
        window.addEventListener("resize", () => {
            if (window.innerWidth > 660) {
                this.navigation = "open"
            } else {
                this.navigation = "closed"
            }
        })
        if (window.innerWidth <= 660) {
            this.navigation = "closed"
        }

        this.isLoading = true
    }
}
