import {action, computed, observable, runInAction, toJS} from "mobx"
import {Gate} from "./lib/gate/Gate"
import {Action, History, Location} from "history"
import {User, Visitor, TaskToken, PathParamsTask} from "./lib/gate/interfaces"
import {Form, FormID} from "./interfaces"
import {route} from "./lib/functions/route"
import {DataStore} from "./data/DataStore"
import {EventDispatcher} from "./lib/gate/EventDispatcher"
import Swal from "sweetalert2"
import {
    INDEX_ENROLLMENT_DOCS,
    TABS_FORMS,
    UPLOAD_DOCS_TAB,
    convertToSubTabsFormatSubmittedDocs,
    getAdmissionsDocumentsSignaturesByRoleAndStatusDoc,
    INITIAL_INDEX,
    YesNo,
    COVID_DOCS_TAB,
    SIGNATURE_DEFINED_USERS,
    CAMPUSES_WITH_VACCINATION_POLICY
} from "./helper/Methods"

const forms: Form[] = [
    {
        form_id: "application_career_information",
        title: "APPLICATION / CAREER INFORMATION",
        type: "web",
        enabled: true
    },
    {
        form_id: "document_for_admission",
        title: "Application Admission",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "document_admission_pkg",
        title: "Admission Documents",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "enrollment_agreement_rtb",
        title: "Enrollment RTB/WFL",
        type: "pdf",
        enabled: true,
        order: 0
    },
    {
        form_id: "enrollment_agreement_rtb_lc",
        title: "Enrollment RTB/WFL",
        type: "pdf",
        enabled: true,
        order: 0
    },
    {
        form_id: "enrollment_agreement_hispanic_rtb",
        title: "Enrollment RTB/WFL",
        type: "pdf",
        enabled: true,
        order: 0
    },
    {
        form_id: "enrollment_agreement_rtb_miami",
        title: "Enrollment RTB/WFL",
        type: "pdf",
        enabled: true,
        order: 0
    },
    {
        form_id: "enrollment_agreement_emp_apc",
        title: "Enrollment EMPH/APC",
        type: "pdf",
        enabled: true,
        order: 1
    },
    {
        form_id: "enrollment_agreement_emp_apc_lc",
        title: "Enrollment EMPH/APC",
        type: "pdf",
        enabled: true,
        order: 1
    },
    {
        form_id: "enrollment_agreement_emp_apc_miami",
        title: "Enrollment EMPH/APC",
        type: "pdf",
        enabled: true,
        order: 1
    },
    {
        form_id: "enrollment_agreement_aas",
        title: "Enrollment AAS",
        type: "pdf",
        enabled: true,
        order: 2
    },
    {
        form_id: "survey_quiz",
        title: "Survey Quiz Form",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "submission",
        title: "submission",
        type: "web",
        enabled: true
    }
]

export class Model {
    public dispatcher = new EventDispatcher()
    public dispatcher2 = new EventDispatcher()
    private validateFunctions: Function[] = []

    @observable
    public isIframeLoading: boolean = false

    @observable
    public formTabSelected: any

    @observable //todo: refine this temporal solution
    public enrollments_agreements_to_sign = [
        {
            id: 0
        },
        {
            id: 1,
            title: "Admissions Documents",
            isChildsDisabled: true
        },
        {
            id: 2,
            title: "Enrollment Agreement",
            isChildsDisabled: true
        },
        {
            id: 3
        }
    ]

    @observable
    public document: UserDocument = {json_data: {}}

    @observable
    public signaturePopup: {
        show: boolean
        signature_key
        mode: "signature" | "initials" | "Parent" | "Advisor" | "School Director"
    } = {
        show: false,
        signature_key: "",
        mode: "Parent"
    }

    @observable
    private isAuth = false

    @observable
    private tabsForm = []

    @observable
    private form_id: FormID = "application_career_information"

    @observable
    public iframeLoaded = false

    @observable
    public campusInformationFooter = {
        name: "",
        address: "",
        state: "",
        phone: ""
    }

    @observable
    public location: Location

    @observable
    public path: string

    @observable
    public visitor: Visitor

    @observable
    public user: User | undefined

    @observable
    public taskToken: TaskToken | undefined

    @observable
    public pathParamsTask: PathParamsTask

    @observable
    public appSettings: any

    @observable
    public popup = {visible: false, message: ""}

    public childBridge: any

    @observable
    public is_form_submitted = false

    private parentBridge: any

    @observable
    public careerInfo: any

    @observable
    public campusesInfo: any

    constructor(public gate: Gate, private history: History, public dataStore: DataStore) {
        this.history = history
        history.listen(this.onHistoryChange.bind(this))
        this.onHistoryChange(history.location, "PUSH") // execute one time
        // @ts-ignore
        this.parentBridge = window.parent.bridge
    }

    public enableForm(form_id: FormID) {
        for (let form of forms) {
            if (form.form_id === form_id) {
                form.enabled = true
                break
            }
        }

        this.dispatcher.dispatch()
    }

    public disableForm(form_id: FormID) {
        for (let form of forms) {
            if (form.form_id === form_id) {
                form.enabled = false
                break
            }
        }
        this.dispatcher.dispatch()
    }

    @action
    public setIframeLoading(status) {
        this.isIframeLoading = status
    }

    @action
    public setFormId(id: FormID, type = "staff") {
        console.log("SET FORM ID=", id)
        this.form_id = id
        this.goTo(`/form/${id}?token=${this.gate.getToken()}&user_id=${this.user?.id}&type=${type}`)
        this.iframeLoaded = false
    }

    @action
    public setFormTabId(id: string) {
        this.form_id = id as FormID
        this.iframeLoaded = false
    }

    @action
    public setAuthFormId() {
        console.log("SET AUTH FORM ID=")
        this.goTo(`/unauthorized`)
        this.iframeLoaded = false
    }

    @computed
    public get form(): Form {
        return forms.find((el) => {
            return el.form_id === this.form_id
        })
    }

    public goTo(url: string) {
        console.log(`GO TO URL:${url}`)
        this.history.push(url)
    }

    public getTabForms() {
        return this.tabsForm
    }

    @action
    public setIndexTab(index) {
        this.enrollments_agreements_to_sign[index].isChildsDisabled = false
    }

    public getIsAuth(): boolean {
        return this.isAuth
    }

    public get enabledItems(): FormID[] {
        let arr = forms.filter((el) => {
            return el.enabled
        })

        return arr.map((el: Form) => {
            return el.form_id
        })
    }

    public getPreviousFormId(): FormID {
        let index: number
        for (let i = 0; i < forms.length; i++) {
            if (forms[i].form_id === this.form_id) {
                index = i
                break
            }
        }
        if (index === 0) {
            return null
        }

        for (let i = index - 1; i >= 0; i--) {
            if (forms[i].enabled) {
                return forms[i].form_id
            }
        }

        return null
    }

    public getFormId(): FormID {
        return this.form_id
    }

    public getNextFormId(): FormID {
        let index: number
        for (let i = 0; i < forms.length; i++) {
            if (forms[i] && forms[i].form_id === this.form_id) {
                index = i
                break
            }
        }

        if (index + 1 === forms.length) {
            return null
        }

        for (let i = index + 1; i < forms.length; i++) {
            if (forms[i].enabled) {
                return forms[i].form_id
            }
        }

        return null
    }

    private onHistoryChange(location: Location, action: Action) {
        this.path = location.pathname
        this.location = location
        console.log("ON HISTORY Change=", this.path)
        if (route("/form/:id(/)", this.path)) {
            // regex
            const {id} = route("/form/:id(/)*", this.path)
            this.form_id = id
        }
    }

    @computed
    public get planStaffUserId(): number {
        if (this.appSettings && this.appSettings.plan_staff_user_id) {
            return parseInt(this.appSettings.plan_staff_user_id)
        }

        return 0
    }

    @computed
    public get agreementStaffUserId(): number {
        // return 60347
        if (this.appSettings && this.appSettings.agreement_staff_user_id) {
            return parseInt(this.appSettings.agreement_staff_user_id)
        }

        return 0
    }

    @action
    public alert(message: string) {
        this.popup.visible = true
        this.popup.message = message
    }

    public get isStaff(): boolean {
        if (!this.visitor) {
            return false
        }
        const role = this.visitor.role
        if (role === "staff" || role === "admin") {
            return true
        }

        return false
    }

    public async checkCampusCode() {
        let json_data
        json_data = this.document.json_data
        let {success} = await this.gate.request(`/check/${this.user.id}`, {
            data: {json_data}
        })

        if (success) {
            json_data.isValidCampusCode = true
            await Swal.fire({
                icon: "success",
                title: `Valid campus code`,
                text: `Thank you! You may now proceed to the next section.`
            })
            const isSuccess = await this.saveAndContinue()
            if (isSuccess) {
                const form_id: FormID = this.getNextFormId()
                this.setFormId(form_id)
            }
        } else {
            delete json_data.isValidCampusCode
            await Swal.fire({
                icon: "error",
                title: `Invalid campus code`,
                text: `You may have an invalid campus code. Please Try again.`
            })
        }
        return success
    }

    public async loadAppSettings() {
        let r = await this.gate.request("/app-settings/get")
        this.appSettings = r.data.json_data
    }

    public async loadMe(): Promise<void> {
        let r = await this.gate.request("/users/get-me")
        if (!r.success) {
            this.alert("API Error!" + JSON.stringify(r.errors))
        }
        this.visitor = r.data
    }

    public async checkTaskToken(token: string, pathParams: any): Promise<void> {
        const {taskUserId, userId} = pathParams
        const {success, data} = await this.gate.request(`/users/get/tasks`, {
            data: {token, taskUserId}
        })
        if (success) {
            this.isAuth = true
            this.taskToken = {...data}
            this.pathParamsTask = {
                userId,
                taskUserId
            }
        }
    }

    public async loadUser(): Promise<any> {
        let r = await this.gate.request(`/users/get-me`)
        this.user = r.data
        return r
    }

    public async loadCareerInformation(): Promise<void> {
        const {data} = await this.gate.request(`/careers`)
        this.careerInfo = data
    }

    public async loadCampuses(): Promise<void> {
        const {data} = await this.gate.request(`/campuses`)
        this.campusesInfo = data
    }

    public getHistory(): History {
        return this.history
    }

    public async preEnrollStudent(): Promise<void> {
        let json_data
        json_data = this.document.json_data
        try {
            const responseSubmit = await this.gate.request(`/users/${this.user.id}/enroll`)
            if (responseSubmit.success) {
                await Swal.fire({
                    icon: "success",
                    title: `Thank you for completing your Enrollment Application`,
                    html: `
                        <p>
                            Your admissions advisor will be in contact with you soon to provide you with the 
                            <strong>Campus Code</strong> to access and complete other enrollment documents.
                        </p>
                    `
                })
            } else {
                delete json_data.isSubmitted
            }
        } catch (error) {}
        runInAction(() => {
            this.is_form_submitted = true
        })
    }

    public async submit(): Promise<void> {
        let json_data
        json_data = this.document.json_data
        // const id = this.user.doc.id
        try {
            const responseSubmit = await this.gate.request(
                `/users/${this.user.id}/answer/${this.pathParamsTask.taskUserId}`,
                {
                    data: {
                        tokenId: this.taskToken.tokenInfo.id
                    }
                }
            )
            if (responseSubmit.success) {
                await Swal.fire({
                    icon: "success",
                    title: `Application submitted.`,
                    text: `Thanks for sign the remaining doc's. The forms are being processed now.`,
                    willClose: () => {
                        window.location.href = process.env.REACT_APP_BEONAIR_PORTAL
                    }
                })
            }
        } catch (error) {
            delete json_data.isStaffSubmitted
        }
        runInAction(() => {
            this.is_form_submitted = true
        })
    }

    public async saveAgreement(): Promise<boolean> {
        let json_data
        const id = this.user.doc.id
        if (this.form.type === "web") {
            // if (!this.validate()) {
            //     return false
            // }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }
        json_data.accepted = true
        let r: any = await this.gate.request(`/docs/${id}/edit`, {
            data: {json_data}
        })
        if (r.success) {
            const {
                data: {
                    json_data: {accepted}
                }
            } = r

            json_data.accepted = accepted
        } else {
            if (json_data.accepted) {
                delete json_data.accepted
            }
        }

        return r.success
    }

    public async saveAndContinue(): Promise<boolean> {
        let json_data
        if (this.form.type === "pdf") {
            if (!this.childBridge) {
                throw new Error("No childBridge! Please check bridge set in admissions-beonair-pdf")
            }
            if (!(await this.childBridge.validate())) {
                return false
            }
            json_data = this.childBridge.json_data
            this.document.json_data = json_data
            this.dispatcher.dispatch()
        }
        console.log("childBridge", this.childBridge)

        if (this.form.type === "web") {
            if (!this.validate()) {
                return false
            }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }

        json_data.forms = json_data.forms || {}
        json_data.forms[this.form.form_id] = this.form
        console.log("Saving and logout form:", this.form_id)
        // if (
        //     this.form_id === READY_TO_ENROLL_FORM &&
        //     json_data.isValidCampusCode &&
        //     json_data.isEnrollmentApplicationDocReady
        // ) {
        //     json_data.hasCampusCode = true
        // }
        try {
            const id = this.user.doc.id

            let r: any = await this.gate.request(`/docs/${id}/edit`, {
                data: {json_data}
            })
            if (r.success) {
                this.dispatcher2.dispatch()
            }
            return r.success
        } catch (error) {
            console.log("Internal server error while saving/logout the doc.", error)
            delete json_data.hasCampusCode
            return false
        }
    }

    public async onSaveFoms(): Promise<boolean> {
        let json_data
        if (this.form.type === "pdf") {
            if (!this.childBridge) {
                throw new Error("No childBridge! Please check bridge set in admissions-beonair-pdf")
            }
            if (!(await this.childBridge.validate())) {
                return false
            }
            json_data = this.childBridge.json_data
            this.document.json_data = json_data
            this.dispatcher.dispatch()
        }
        console.log("childBridge", this.childBridge)

        if (this.form.type === "web") {
            if (!this.validate()) {
                return false
            }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }

        json_data.forms = json_data.forms || {}
        json_data.forms[this.form.form_id] = this.form

        console.log("my current JSON DATA:", toJS(json_data))

        try {
            const id = this.user.doc.id

            let r: any = await this.gate.request(`/docs/${id}/edit`, {
                data: {json_data}
            })
            return r.success
        } catch (error) {
            console.log("Internal server error while saving the doc.", error)
            return false
        }
    }

    public async save(): Promise<boolean> {
        let json_data
        if (this.form.type === "web") {
            if (!this.validate()) {
                return false
            }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }

        json_data.forms = json_data.forms || {}
        json_data.forms[this.form.form_id] = this.form
        console.log("Saving form:", this.form_id)

        try {
            const id = this.user.doc.id
            let r: any = await this.gate.request(`/docs/${id}/edit`, {
                data: {json_data}
            })
            return r.success
        } catch (error) {
            console.log("Internal server error while saving the doc.", error)
            return false
        }
    }

    public async uploadDocument(file: File, id: string) {
        const IS_MULTI = false
        const formData = new FormData()
        formData.append(id, file)
        const data = await this.gate.request(
            `/uploads`,
            {
                data: formData
            },
            IS_MULTI
        )
        return data
    }

    public async signOut() {
        const json_data = this.document.json_data
        if (json_data.isSubmitted) {
            delete json_data.isSubmitted
            const id = this.user.doc.id
            await this.gate.request(`/docs/${id}/edit`, {
                data: {json_data}
            })
        }
        this.parentBridge.signOut()
    }

    public async getStaffSignature(
        type: "Advisor" | "Parent" | "School Director"
    ): Promise<object | null> {
        if (!this.currentSignature) {
            let r = await this.gate.request("/signatures/get-my-staff", {data: {type: type}})
            if (r.success) {
                this.currentSignature = r.data
            } else {
                this.currentSignature = null
            }
        }

        return this.currentSignature
    }

    public addValidateFunction(f: Function) {
        for (let item of this.validateFunctions) {
            if (item === f) {
                return
            }
        }

        this.validateFunctions.push(f)
    }

    public removeValidateFunction(f: Function) {
        console.log("removeValidateFunction")
        // @ts-ignore
        console.log(f.functionId)

        console.log(
            this.validateFunctions.map((e) => {
                // @ts-ignore
                return e.functionId
            })
        )
        for (let i = 0; i < this.validateFunctions.length; i++) {
            // @ts-ignore
            if (this.validateFunctions[i].functionId === f.functionId) {
                this.validateFunctions.splice(i, 1)
                console.log(
                    this.validateFunctions.map((e) => {
                        // @ts-ignore
                        return e.functionId
                    })
                )
                return
            }
        }
    }

    public validate(): boolean {
        console.log("onValidate:", this.validateFunctions)
        let results = []
        for (let f of this.validateFunctions) {
            const r = f()
            results.push(r)
        }

        if (results.includes(false)) {
            return false
        }
        return true
    }

    public async loadAll() {
        await this.loadUser()
        this.document = this.user.doc
        console.log("MODEL>LOAD ALL")
        console.log(toJS(this.document))
        this.form_id =
            (this.document.json_data.progress as FormID) || "application_career_information"

        if (this.document) {
            this.tabsForm = TABS_FORMS

            const tabs = convertToSubTabsFormatSubmittedDocs(
                this.document.json_data.submittedForms,
                this.enrollments_agreements_to_sign,
                this.document.json_data.role
            )
            this.tabsForm[INDEX_ENROLLMENT_DOCS].subTabs = [...tabs]
            // todo: add control to show covid option
            const {
                json_data: {
                    uploads,
                    vaccinated,
                    role,
                    extra: {
                        campus: {code}
                    }
                }
            } = this.document
            const [
                {code: lombardCampusCode},
                {code: chicagoCampusCode}
            ] = CAMPUSES_WITH_VACCINATION_POLICY

            if (code === lombardCampusCode || code === chicagoCampusCode) {
                if (uploads && uploads.length > 0 && vaccinated === YesNo.yes) {
                    this.tabsForm = [...this.tabsForm, UPLOAD_DOCS_TAB]
                } else {
                    this.tabsForm = [...this.tabsForm, COVID_DOCS_TAB]
                }
            }

            const admissionDocsTab = getAdmissionsDocumentsSignaturesByRoleAndStatusDoc(
                this.user.doc
            )
            if (role && role !== SIGNATURE_DEFINED_USERS.keyParent.role) {
                this.tabsForm[INDEX_ENROLLMENT_DOCS - 1].subTabs[
                    INITIAL_INDEX
                ].childSignaturesKey = [...admissionDocsTab]
            }
            if (role && role === SIGNATURE_DEFINED_USERS.keyParent.role) {
                //todo remove admissions docs
                delete this.tabsForm[INDEX_ENROLLMENT_DOCS - 1].bgColor
                delete this.tabsForm[INDEX_ENROLLMENT_DOCS - 1].isChildsFilled
                delete this.tabsForm[INDEX_ENROLLMENT_DOCS - 1].subTabs[INITIAL_INDEX]
                    .childSignaturesKey
                delete this.tabsForm[INDEX_ENROLLMENT_DOCS - 1].subTabs[INITIAL_INDEX].bgColor
                // todo, find a better way to do this enable tab.
                this.setIndexTab(INDEX_ENROLLMENT_DOCS - 1)
            }
            // console.log('All checkmarks tabs:',toJS(this.tabsForm));
        }
        this.dispatcher.dispatch()
        this.dispatcher2.dispatch()
    }

    public async deleteSupportedDocument(id: number) {
        const r = await this.gate.request(`/uploads/${id}/delete`)
        return r
    }

    public isEnableSubmitButton() {
        //todo change here admissions docs submit enable
        const [ADMISSION_DOCS_IDX, ENROLLMENT_AGREEMENTS_IDX] = [1, 2]
        const enrollmentDocsToSign = this.enrollments_agreements_to_sign
        if (
            enrollmentDocsToSign[ADMISSION_DOCS_IDX].isChildsDisabled !== true &&
            enrollmentDocsToSign[ENROLLMENT_AGREEMENTS_IDX].isChildsDisabled !== true
        ) {
            return false
        }
        return true
    }

    private currentSignature
}

export interface SignatureVO {
    id
    user_id
    json_data: {
        img: string
    }
    ip // "172.17.0.1"
    created_at // "2019-10-17T11:22:43.823Z"
    updated_at // "2019-10-17T11:22:43.823Z"
    deleted_at
}

interface UserDocument {
    id?: number
    // pack_id
    // visitor_id
    // user_id
    // user: User
    // type
    json_data: JsonDocument
    // created_at
    // updated_at
    // deleted_at
}

export interface JsonDocument {
    [key: string]: string | any
}

export interface CareerDocument {
    [key: string]: any
}
